<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-19 16:19:44
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-08-15 14:44:30
 * @Description  : 选择列弹窗
-->
<template>
  <el-dialog
    :title="$t('select_columns')"
    :visible.sync="dialogFormVisible"
    width="50%"
    :modal="false"
    class="container"
  >
    <!-- {{ $t("select_columns") }} -->
    <div class="h_line"></div>
    <el-form :model="form" ref="form">
      <el-checkbox-group
        v-model="form.checkList"
        @change="onChange"
        class="checkbox-style"
      >
        <el-checkbox :label="o.value" v-for="o in columns" :key="o.value">{{
          o.label
        }}</el-checkbox>
      </el-checkbox-group>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("apply_change")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      columns: [
        {
          value: "1",
          label: "Device ID",
        },
        {
          value: "2",
          label: "Device Name",
        },
        {
          value: "3",
          label: "Device Status",
        },
        {
          value: "4",
          label: "Alert Status",
        },
        {
          value: "5",
          label: "Latest Measurements",
        },
        {
          value: "6",
          label: "Start Time",
        },
        {
          value: "7",
          label: "Scene ID",
        },
        {
          value: "8",
          label: "Records Total",
        },
        {
          value: "9",
          label: "Records With Alerts Total",
        },
        {
          value: "10",
          label: "Owner",
        },
        {
          value: "11",
          label: "End Time",
        },
        {
          value: "12",
          label: "Last Location ",
        },
      ],
      dialogFormVisible: false,
      form: {
        checkList: [],
      },
    };
  },
  methods: {
    show() {
      this.showDialog();
    },
    showDialog() {
      this.dialogFormVisible = true;
    },
    onChange(value) {
      console.log("value: ", value);
      // if(value.indexOf('-1') !== -1){
      // 	this.checkList = ['-1'];
      // 	this.disabled = true;
      // 	this.moshi = this.moshi.map(e=>{
      // 		if(e.value != '-1'){
      // 			e.disabled = true;
      // 			return e;
      // 		}
      // 		return e;
      // 	})
      // } else {
      // 	this.moshi = this.moshi.map(e=>{
      // 		e.disabled = false;
      // 		return e;
      // 	})
      // }

      // this.form = Object.assign({}, this.form, {
      // 	operationMode: this.checkList.join(","),
      // });
    },

    handelOk() {
      // let params = {
      //   ...this.addForm,
      //   model: "",
      //   serverType: "",
      //   port: "",
      // };
      // addByDeviceIdApi(params)
      //   .then((r) => {
      //     console.log("=====", r);
      //     this.$notify({
      //       title: this.$t("tip"),
      //       message: this.$t("add_logger_success"),
      //       type: "success",
      //     });
      //     this.dialogFormVisible = false;
      //     this.$emit("refresh");
      //   })
    },
  },
  props: {},
  components: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .h_line {
    width: 100%;
    height: 1px;
    background: #bbbbbb;
    // margin-top: -20px;
  }
}
/deep/.el-dialog__body {
  padding: 0;
}
.checkbox-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;

  .el-checkbox {
    flex-basis: calc(33.333% - 148px);
    margin-bottom: 10px;
    flex-grow: 1; /* 这个属性确保在有空间时元素可以伸展 */
    flex-shrink: 0; /* 防止元素在空间不足时收缩 */
    border: 1px solid #BBBBBB;
    width: 461px;
    height: 22px;
    font-size: 20px;
    line-height: 22px;
    padding: 0 5px 5px 5px;
    border-radius: 4px;
   font-weight: normal;
  }
}
</style>
